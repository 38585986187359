<template>
  <b-card class="detailing">
    <b-row>
      <b-col cols="12" xl="6">
        <h1 class="detailing__title">Detalhes da Conta</h1>
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="UserIcon" size="18" />
              <h4 class="mb-0 ml-75">Dados Pessoais</h4>
            </div>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Corretor ou Imobiliária" label-for="account-name">
                <b-form-input
                    disabled />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="CPF / CNPJ" label-for="account-document">
                <b-form-input
                    disabled />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Email" label-for="account-document">
                <b-form-input
                    disabled />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Nome Completo / Razão Social" label-for="account-document">
                <b-form-input
                    disabled />
            </b-form-group>
          </b-col>
          <b-col v-if="registro.typePerson === 'F'" sm="6">
            <b-form-group label="Data de Nascimento" label-for="account-document">
                <b-form-input
                    disabled />
            </b-form-group>
          </b-col>
          <b-col v-if="registro.typePerson === 'F'" sm="6">
            <b-form-group label="Celular" label-for="account-document">
                <b-form-input
                    disabled />
            </b-form-group>
          </b-col>
          <b-col v-if="registro.typePerson === 'F'" sm="6">
            <b-form-group label="Documento de Identificação" label-for="account-document">
                <b-form-input
                    disabled />
            </b-form-group>
          </b-col>
          <b-col v-if="registro.typePerson === 'F'" sm="6">
            <b-form-group label="Região de Atuação" label-for="account-document">
                <b-form-input
                    disabled />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>

          <b-col v-if="registro.typePerson === 'F'" sm="6">
            <b-form-group label="Status" label-for="account-document">
              <b-form-select v-model="selected" :options="selectOptions" />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>

          <b-col>
            <div class="detailing__text-area">
              <h1 class="detailing__title">Feedback</h1>
              <p>
                Para cada opção (rejeitada ou confirmada) haverá um campo de texto
                livre, onde a Divvy digitará o feedback a ser enviada (deverá ser um
                editor), que será enviado para o e-mail do corretor/imobiliária que
                realizou a solicitação.
              </p>

              <quill-editor v-model="content" :options="snowOption" />
            </div>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="6">
        <b-row>
          <b-col>
        <h1 class="detailing__title">Documentos</h1>
        <ul class="detailing__grid">
          <li
            v-for="(attachment, key) in $route.params.data.attachments"
            :key="key"
            class="detailing__document-card">
            <div>
              <img
                class="mt-2"
                src="~@/assets/images/icons/doc.png"
                alt="document icon">
            </div>
            <h5 class="mt-2">{{ attachment.file }}</h5>
            <b-form-select v-model="selected" :options="selectOptions" />
          </li>
        </ul>
      </b-col>
        </b-row>
        <b-row>
          <b-col>
        <div class="detailing__text-area">
          <h1 class="detailing__title">Feedback</h1>
          <p>
            Para cada opção (rejeitada ou confirmada) haverá um campo de texto
            livre, onde a Divvy digitará o feedback a ser enviada (deverá ser um
            editor), que será enviado para o e-mail do corretor/imobiliária que
            realizou a solicitação.
          </p>

          <quill-editor v-model="content" :options="snowOption" />
        </div>
      </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-button class="mt-4" variant="primary">Salvar</b-button>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormSelect, BButton, BFormInput, BFormGroup } from 'bootstrap-vue'
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'DelibetationDetailed',
  components: {
    quillEditor,
    BRow,
    BCol,
    BCard,
    BFormSelect,
    BButton,
    BFormInput,
    BFormGroup,
  },

  data: () => ({
    registro: {
      typePerson: 'F',
    },
    snowOption: {
      theme: 'snow',
      content: '',
      codeSnow: '',
    },
    selectOptions: [
      { value: 'default', text: 'Em Avaliação' },
      { value: 'confirmado', text: 'Confirmado' },
      { value: 'rejeitado', text: 'Rejeitado' },
    ],
  }),

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import './sass/_variables.scss';

ul {
  padding-inline-start: 0px !important;
}

.detailing {
  box-sizing: border-box;
  padding: 10px 20px;

  & span,
  & td {
    color: $color-black;
  }

  &__title {
    font-family: $source-sans-pro;
    color: $color-black;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 900px) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &__document-card {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);

    border-radius: 9px;
    box-sizing: border-box;
    width: 180px;
    padding: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 900px) {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  &__feedback-description {
    font-size: 16px;
    line-height: 19px;
    color: #a6adba;
    width: 80%;
    @media (min-width: 900px) {
      max-width: 600px;
    }
  }

  .ql-container {
    min-height: 200px;
  }
}
</style>
